import React from 'react'
import Header from '../components/header'
import Hire from '../components/hire'

const hire_page = () => {
  return (
    <div>
      <Header />
      <Hire />
    </div>
  )
}

export default hire_page