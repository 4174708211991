import React from 'react'
import Header from '../components/header'
import Contact from '../components/contact'
const contact_page = () => {
  return (
    <div>
      <Header />
      <Contact />
    </div>
  )
}

export default contact_page